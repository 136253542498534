<template>
  <div class="container">
    <div class="goods">
      <div class="title">{{ goodsTitle }}</div>
      <img class="cover" :src="goodsImgUrl" alt="cover" />
      <div
        class="logo"
        :style="{backgroundImage: 'url(' + brandImgUrl + ')'}"
      ></div>
    </div>
    <img
      class="box"
      src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01870d22-7c16-12c2-f21d-5ea394aef754?x-oss-process=style/origin"
      alt="box"
    />
    <img class="avatar" :src="userAvatar" alt="avatar" />
    <div class="info">
      <div class="nickname">{{ nickName }}</div>
      <div class="desc">
        {{ date }}，参加了{{ clubName }}的活动，抽中了一张粗门品牌小绿卡
      </div>
      <div class="footer">
        <div class="club">{{ clubName }}<br />扫码和他们一起玩</div>
        <img class="qrcode" :src="qrcode" alt="qrcode" />
      </div>
    </div>
  </div>
</template>

<script>
import $fetch from "@/utils/fetch.js";
export default {
  name: "greenCard",
  data() {
    return {
      qrcode: "",
    };
  },
  created() {
    const {
      clubId,
      clubName,
      userAvatar,
      nickName,
      date,
      goodsImgUrl,
      brandImgUrl,
      goodsTitle,
    } = this.$route.query;
    this.clubId = clubId;
    this.clubName = clubName;
    this.userAvatar = userAvatar;
    this.nickName = nickName;

    const _date = new Date(date * 1e3);
    const year = _date.getFullYear();
    const month = _date.getMonth() + 1;
    const day = _date.getDate();
    this.date = `${year}年${month}月${day}日`;

    this.goodsImgUrl = goodsImgUrl;
    this.brandImgUrl = brandImgUrl;
    this.goodsTitle = goodsTitle;

    this.getWechatCode(clubId);
  },
  methods: {
    /** 获取活动的小程序码 */
    getWechatCode(userId) {
      const _ = this;
      $fetch.post("ShareProfile", { userId }).then((code) => {
        _.qrcode = "data:image/jpeg;base64, " + code.mini_program_code_image;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$multipleCount: 1.2057;

.container {
  @include column(flex-end, stretch);
  position: relative;
  width: 100vw;
  height: 166.23vw;
  overflow: hidden;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  background: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01873bdc-1bb6-42ba-3cac-a1a7456e45b9?x-oss-process=style/origin")
    no-repeat center top / cover;

  > .goods {
    position: absolute;
    left: 51.7%;
    top: px2rem(50 * $multipleCount);
    transform-origin: center;
    transform: translateX(-50%) rotate(19deg);
    width: px2rem(232 * $multipleCount);
    height: px2rem(305 * $multipleCount);

    .title {
      @include multi-ellipsis(2);
      width: px2rem(121.36 * $multipleCount);
      height: px2rem(60 * $multipleCount);
      line-height: px2rem(30 * $multipleCount);
      color: #222;
      font-size: px2rem(22.58 * $multipleCount);
      font-weight: 600;
      font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
      margin: px2rem(21.18 * $multipleCount) px2rem(22.58 * $multipleCount) 0;
    }

    .cover {
      width: 100%;
      height: px2rem(145.35 * $multipleCount);
      object-fit: contain;
    }

    .logo {
      height: px2rem(76 * $multipleCount);
      width: px2rem(140 * $multipleCount);
      margin: 0 px2rem(24 * $multipleCount);
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;
    }
  }

  > .box {
    position: absolute;
    left: px2rem(94 * $multipleCount);
    top: px2rem(302 * $multipleCount);
    width: px2rem(277 * $multipleCount);
    height: px2rem(218 * $multipleCount);
  }

  > .avatar {
    position: absolute;
    left: 50.1%;
    top: px2rem(626 * $multipleCount);
    transform: translateX(-50%);
    width: px2rem(108 * $multipleCount);
    height: px2rem(108 * $multipleCount);
    border-radius: 50%;
  }

  > .info {
    padding: 0 px2rem(32 * $multipleCount);
    height: px2rem(374 * $multipleCount);

    .nickname {
      color: #fff;
      font-size: px2rem(32 * $multipleCount);
      margin-top: px2rem(78 * $multipleCount);
      text-align: center;
      font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
    }

    .desc {
      color: rgba(255, 255, 255, 0.5);
      font-size: px2rem(24 * $multipleCount);
      text-align: center;
      margin-top: px2rem(16 * $multipleCount);
      line-height: 1.4;
    }

    .footer {
      @include row(space-between, center);
      margin-top: px2rem(40 * $multipleCount);
      padding-top: px2rem(10 * $multipleCount);

      .club {
        color: rgba(255, 255, 255, 0.3);
        font-size: px2rem(24 * $multipleCount);
      }

      .qrcode {
        width: px2rem(94 * $multipleCount);
        height: px2rem(94 * $multipleCount);
        background-color: #fff;
        object-fit: cover;
        border-radius: 50%;
        border: px2rem(4 * $multipleCount) solid #fff;
      }
    }
  }
}
</style>
